import gql from 'graphql-tag';

export const Dish = gql`
  fragment Dish on Dish {
    id
    title
    category {
      id
      title
    }
    status
    isReward
    rewardPoints
    isDiscounted
    description
    type
    pizzaBasePrice {
      id

      price {
        intValue
        shift
      }
    }
    price {
      id
      size
      isDefault
      price {
        currencyCode
        intValue
        shift
      }
    }
    images {
      id
      md {
        url
      }
    }
  }
`;
