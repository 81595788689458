import { Restaurant } from 'generated/types';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useGetWebsiteDataForBiz } from 'graphql/hooks/websiteAutomation.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { WebsiteFormValues } from './types';
import WebsiteForm from './websiteForm';

const WebAuotmationForm: React.FC = () => {
  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const { restaurantId } = useActiveRestaurant();
  const { data: restaurantData } = useGetRestaurantById(restaurantId || '', 'PROFILE');
  const data = useGetWebsiteDataForBiz(restaurantId);
  const [restauranDataState, setRestaurantDataState] = useState<Restaurant>(restaurantData);
  const [createData, setCreateData] = useState(true);

  const [initialValues, setInitialValues] = useState<WebsiteFormValues>({
    websiteUrl: '',
    customDomain: '',
    googleSiteVerification: '',
    facebookLink: '',
    yelpLink: '',
    instagramLink: '',
    legalEntityName: '',
    headline1: '',
    googleAnalyticsTrackingId: '',
    googleAdsId: '',
    displayImage: null,
    displayImageData: null,
    keywords: '',
    seoPageTitle: '',
    seoPageDescription: '',
    about: [
      {
        heading: '',
        description: '',
        image: null,
        imageData: null
      },
      {
        heading: '',
        description: '',
        image: null,
        imageData: null
      },
      {
        heading: '',
        description: '',
        image: null,
        imageData: null
      }
    ],
    menuDescription: '',
    menuImage: null,
    menuImageData: null,
    testimonials: [],
    orderPlatform: [],
    version: 0
  });
  const { name, subName } = restauranDataState || {};
  const resName = name || '';
  const resSubName = subName || '';
  useEffect(() => {
    if (restaurantData && restaurantData.ownerGetRestaurantById) {
      setRestaurantDataState(restaurantData.ownerGetRestaurantById);
    }
  }, [restaurantData]);
  useEffect(() => {
    if (data && data.getWebsiteDataForBiz) {
      const {
        url,
        customDomain,
        menuHeadline,
        legalEntityName,
        googleSiteVerification,
        displayImage,
        googleAnalyticsTrackingId,
        googleAdsId,
        seoPageTitle,
        seoPageDescription,
        headline1,
        about,
        menuPicture,
        fbLink,
        yelpLink,
        instaLink,
        testimonials,
        keywords,
        orderPlatform,
        version,
        tikTokLink,
        blogspostLink,
        googleReviewsLink,
        yelpWriteReviewLink,
        googleWriteReviewLink
      } = data.getWebsiteDataForBiz;

      setInitialValues({
        websiteUrl: url,
        customDomain: customDomain,
        facebookLink: fbLink,
        instagramLink: instaLink,
        yelpLink: yelpLink,
        tikTokLink: tikTokLink,
        blogspostLink: blogspostLink,
        googleReviewsLink: googleReviewsLink,
        yelpWriteReviewLink: yelpWriteReviewLink,
        googleWriteReviewLink: googleWriteReviewLink,
        legalEntityName: legalEntityName,
        headline1,
        googleAnalyticsTrackingId,
        googleSiteVerification,
        googleAdsId,
        seoPageTitle: seoPageTitle ? seoPageTitle : `${resName} ${resSubName && `, ${resSubName}`} - Order Food Online`,
        seoPageDescription,
        displayImage: displayImage ? displayImage : null,
        displayImageData: null,
        keywords: keywords || '',
        about: [
          {
            heading: about ? about[0].title : '',
            description: about ? about[0].description : '',
            image: about ? about[0].picture : null,
            imageData: null
          },
          {
            heading: about ? about[1].title : '',
            description: about ? about[1].description : '',
            image: about ? about[1].picture : null,
            imageData: null
          },
          {
            heading: about ? about[2].title : '',
            description: about ? about[2].description : '',
            image: about ? about[2].picture : null,
            imageData: null
          }
        ],
        menuDescription: menuHeadline,
        menuImage: menuPicture ? menuPicture : null,
        menuImageData: null,
        testimonials: testimonials
          ? testimonials.map((testimonial: any) => {
              return {
                name: testimonial.name || '',
                text: testimonial.text || '',
                rating: testimonial.rating || 0
              };
            })
          : [],
        orderPlatform: orderPlatform
          ? orderPlatform.map((platform: any) => {
              return {
                id: platform.id ? platform.id : null,
                name: platform.name ? platform.name : '',
                url: platform.url ? platform.url : ''
              };
            })
          : [],
        version: version
      });
      setCreateData(false);
    }
  }, [data, resName, resSubName]);

  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'WEBSITE',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  return <WebsiteForm initialValues={initialValues} getUploadId={getUploadId} bizId={restaurantId} createData={createData} />;
};

export default WebAuotmationForm;
