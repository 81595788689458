import React, { useState } from 'react';
import { Formik, getIn, FormikHelpers } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog } from '@lokobee/lokobee-ui';
import validations from './validations';
import { Box, makeStyles, DialogTitle, TextField } from '@material-ui/core';
import { useUpdateDishCategory } from 'graphql/hooks/dish.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useSnackbar } from 'notistack';

interface ICategory {
  title: string;
}

interface IProps {
  categoryId: string;
  title: string;
}

const UpdateCategory = ({ categoryId, title }: IProps) => {
  const initialValues: ICategory = {
    title: title
  };

  const { restaurantId } = useActiveRestaurant();

  const [open, setOpen] = useState(false);

  const { updateDishCategory } = useUpdateDishCategory();

  const snackbar = useSnackbar();

  const classes = makeStyles((theme) => ({
    editIcon: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5),
      color: 'white',
      cursor: 'pointer',
      borderRadius: '4px',
      backgroundColor: theme.palette.info.main,
      '&:hover': {
        backgroundColor: theme.palette.info.dark
      }
    }
  }))();

  const onSubmit = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
    try {
      const { data } = await updateDishCategory({
        variables: {
          input: {
            id: categoryId,
            bizId: restaurantId,
            title: values.title
          }
        }
      });
      if (data && data.updateDishCategory) {
        snackbar.enqueueSnackbar('Dish category updated successfully !', {
          variant: 'success'
        });
      } else {
        snackbar.enqueueSnackbar('Update request failed.', {
          variant: 'error'
        });
      }
    } catch (e) {
      setTimeout(() => {
        setOpen(true);
        snackbar.enqueueSnackbar('Error in updating dish category.', {
          variant: 'error'
        });
        console.log(e);
      }, 2000);
    }
  };

  return (
    <>
      <Box>
        <EditIcon onClick={() => setOpen(true)} fontSize="small" className={classes.editIcon} />
      </Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validations} enableReinitialize={true}>
        {({ values, handleSubmit, handleReset, handleChange, errors, touched, handleBlur }) => (
          <Box>
            <Dialog open={open} setOpen={setOpen} dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true }}>
              <DialogTitle id="form-dialog-title">Update Dish Category</DialogTitle>
              <Dialog.DialogContent>
                <TextField
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                  name="title"
                  label="Category title*"
                  error={touched['title'] && errors['title'] != '' && errors['title'] !== undefined}
                  helperText={touched['title'] && errors['title'] != '' && errors['title'] !== undefined ? errors['title'] : ''}
                  fullWidth
                  autoFocus
                />
              </Dialog.DialogContent>
              <Dialog.DialogActions disableSaveBtn={!!getIn(errors, 'title')} saveBtnText="Save" cancelBtnText="Cancel" onSave={handleSubmit} onCancel={handleReset} />
            </Dialog>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default UpdateCategory;
