import { gql } from 'apollo-boost';
import { Dish } from 'graphql/fragments/dish.fragments';

export const getRewardItemsQuery = gql`
  query getRewardItems($input: GetRewardItemsInput!) {
    getRewardItems(input: $input) {
      ...Dish
    }
  }
  ${Dish}
`;

export const getDishesQuery = gql`
  query getAllDishes($input: GetAllDishesInput!) {
    getAllDishes(input: $input) {
      ...Dish
    }
  }
  ${Dish}
`;

export const getDishQuery = gql`
  query getDish($input: GetDishInput!) {
    getDish(input: $input) {
      id
      title
      category {
        id
        title
      }
      status
      isAlcohol
      isDiscounted
      isReward
      rewardPoints
      type
      description
      status
      tags
      price {
        id
        size
        isDefault
        price {
          currencyCode
          intValue
          shift
        }
        discountAmount
      }
      images {
        id
        md {
          url
        }
      }
      extraGroups {
        id
        title
        minSelect
        maxSelect
        multiQtyPerItem
        isOptional
        items {
          id
          title
          price {
            intValue
            shift
            currencyCode
          }
        }
      }

      pizzaBasePrice {
        id
        size {
          id
          title
        }
        isDefault
        price {
          intValue
          shift
        }
        discountAmount
      }
      pizzaCrust {
        id
        partId
        title
        description
        isDefault
        prices {
          size {
            id
            title
          }
          price {
            intValue
            shift
          }
        }
      }
      pizzaSauce {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
          prices {
            id
            size {
              id
              title
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
            }
          }
        }
      }
      pizzaCheese {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
          prices {
            id
            size {
              id
              title
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
            }
          }
        }
      }
      pizzaToppingGroups {
        id
        title
        description
        maxSelect
        items {
          id
          partId
          groupPartId
          title
          description
          preset
          prices {
            id
            size {
              id
              title
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
            }
          }
        }
      }
    }
  }
`;
