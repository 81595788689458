import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Box, TextField, Button, Fab, Switch, Divider } from '@material-ui/core';
import { Formik, FieldArray, getIn, FormikHelpers } from 'formik';
import { map, isEmpty, filter } from 'lodash';
import { useParams } from 'react-router-dom';
import UploadImage from 'components/UploadImage';
import CategoriesField from './CategoriesField';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddDishOptionDialog from './AddDishOptionDialog';
import AddIcon from '@material-ui/icons/Add';
import validations from './validations';
import DishChoice from './DishChoice';
import CancelBtn from 'components/CancelBtn';
import { IFormValues, ICategory, DISH_TYPES } from './types';
import { useCreateDishMutation, useUpdateDishMutation, useCreateDishCategory } from 'graphql/hooks/dish.hooks';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useSnackbar } from 'notistack';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useStyles } from './style';
import { convertResponseToFormValues, convertPriceToPoints } from './util';
import { useHistory } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useStore } from 'store';
import Swal from 'sweetalert2';
import { MAX_REDEEM_POINTS_PER_USAGE, PRICE_POINT_RATIO } from '../../constants';
import { convertPriceTo, getPercentageDecrease } from 'util/number';
import { CATEGORY, HOME } from 'util/strings';

const initialValues: IFormValues = {
  dishName: '',
  visible: true,
  isDiscounted: false,
  isAlcohol: false,
  isReward: false,
  popular: false,
  category: '',
  description: '',
  dishOptions: [],
  prices: [
    {
      size: 'Regular',
      price: '',
      discountAmount: '', //Cents
      discountPercent: '-',
      isDiscounted: false
    }
  ],
  rewardPoints: 0,
  dishType: 'REGULAR'
};

interface IProps {
  dish: any;
  dishType: DISH_TYPES;
}

const RegularDishAddOrEditPage = ({ dish, dishType }: IProps) => {
  const { dishId } = useParams() as any;

  const {
    state: { restaurant }
  } = useStore();

  const [intialFormValues, setInitialFormValues] = useState<IFormValues>(initialValues);

  //const { dish } = useGetDishQuery(dishId);

  const [dishImageInitialValue, setDishImageInitialValue] = useState<string | undefined>(undefined);

  const uploadImageRef: any = React.useRef(null);

  const history = useHistory();

  const [redirectToMenu, setredirectToMenu] = useState(false);

  const [copying, setCopying] = useState(false);

  const [categories, setCategories] = useState<ICategory[]>([]);

  //const [dishType, setDishType] = useState<DISH_TYPES>('REGULAR');

  const classes = useStyles();

  const snackbar = useSnackbar();

  const { createDish, restaurantId } = useCreateDishMutation();

  const { updateDish } = useUpdateDishMutation();

  const { createDishCategory } = useCreateDishCategory();

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const [dishImage, setDishImage] = useState<{ filename: string | null; imageFiledata: string | null; hasError: boolean }>({ filename: null, imageFiledata: null, hasError: true });

  const { data: restaurantRewardSetting } = useGetRestaurantById(restaurant, 'REWARD_SETTING');

  const { rewardSetting = {} } = restaurantRewardSetting ? restaurantRewardSetting.ownerGetRestaurantById : {};

  const { maxRedeemPointsPerUsage = MAX_REDEEM_POINTS_PER_USAGE, pricePointRatio = PRICE_POINT_RATIO } = rewardSetting || {};

  useEffect(() => {
    const output = convertResponseToFormValues(dish);

    const convertedPoints = convertPriceToPoints(output.prices[0].price, pricePointRatio);

    //Setting reward PTS
    const points = output.rewardPoints > 0 ? output.rewardPoints : convertedPoints;

    output.rewardPoints = points;

    const { images } = dish || { images: [] };

    if (images && images.length) {
      const {
        md: { url }
      } = images[0];

      setDishImageInitialValue(url);
    }

    setInitialFormValues(output);
  }, [dish, pricePointRatio]);

  const notify = (icon: 'error' | 'success', message: string) => {
    Swal.fire({
      icon: icon,
      text: message
    });
  };

  const _onSubmit = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
    setSubmitting(true);
    const { dishName, category, description, prices, visible, dishOptions, popular, isReward, isDiscounted, isAlcohol, rewardPoints } = values;

    const points = rewardPoints ? parseInt(rewardPoints) : 0;

    /**Checking for reward eligiblity */
    if (!isEmpty(rewardSetting)) {
      let isExtraFree = true;

      const multiplePrices = prices.length > 1 ? true : false;

      dishOptions.forEach((group: any) => {
        group.items.forEach((item: any) => {
          const toppingPrice = parseFloat(item.price);

          if (toppingPrice > 0) {
            isExtraFree = false;
          }
        });
      });

      try {
        if ((multiplePrices || !isExtraFree) && isReward) {
          setSubmitting(false);

          throw Error('Dishes having multiple size or extra choice price cannot me marked as reward item.');
        }

        if (points > maxRedeemPointsPerUsage && isReward) {
          setSubmitting(false);
          throw Error(`Dish reward points cannot be more than "Max Redeemable Points Per Usage (${maxRedeemPointsPerUsage})"`);
        }
      } catch (error) {
        notify('error', (error as any).message);
        return;
      }
    }
    /**End of reward eligiblity check */

    const status = visible ? 'ACTIVE' : 'HIDDEN';

    const price = map(prices, ({ id, size, price, discountAmount }, index: number) => {
      let isDefault = false;
      if (index === 0) {
        isDefault = true;
      }

      let priceObject: any = {
        id: copying ? undefined : id,
        size: size,
        price: {
          currencyCode: 'usd',
          strValue: price
        },
        isDefault
      };

      if (discountAmount) {
        priceObject.discountAmount = Number(convertPriceTo(discountAmount, 'CENT'));
      }

      return priceObject;
    });

    const extraGroups = map(dishOptions, ({ id: dishOptionId, title, min, max, items, isOptional, isMultiQnty }) => {
      const _items = map(items, ({ id, title: itemTitle, price: itemPrice }) => ({
        id: typeof id === 'string' && !copying ? id : undefined,
        title: itemTitle,
        price: {
          currencyCode: 'usd',
          strValue: itemPrice
        }
      }));

      /**
       * isOptional field is saved as string in form. It needs to be converted to boolean before mutation.
       */
      const isOptional1 = isOptional === 'true' || false;

      return {
        id: typeof dishOptionId === 'string' && !copying ? dishOptionId : undefined,
        title: title,
        minSelect: min,
        maxSelect: max,
        items: _items,
        multiQtyPerItem: isMultiQnty === 'true' || false,
        isOptional: isOptional1
      };
    });

    const tags = popular && (dishImage.filename || dishImageInitialValue) ? ['POPULAR'] : [];

    try {
      let imageId = '';

      const { filename } = dishImage;

      if (filename) {
        const { data: uploadImageResponse } = await createImageUploadUrl({
          variables: {
            input: {
              usage: 'DISH_IMG',
              fileName: filename
            }
          }
        });

        if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
          const { id, url } = uploadImageResponse.createImageUploadUrl;
          imageId = id;
          if (dishImage.imageFiledata) {
            await uploadImage(url, dishImage.imageFiledata);
          } else {
            throw Error('Image data not available');
          }
        } else {
          setSubmitting(false);
          throw Error('Image Upload error');
        }
      } else {
        if (dishImageInitialValue) {
          const { images } = dish;

          const { id } = images[0];

          imageId = id;
        }
      }

      // Check if category present
      let categoryId = '';
      const oldCategory = filter(categories, (catg: ICategory) => catg.title === category)[0];

      if (oldCategory) {
        categoryId = oldCategory.id;
      } else {
        if (dishType === 'REGULAR' || dishType === 'TABLE_SERVICE') {
          // If category not present create new category
          const {
            data: { createDishCategory: newCategory }
          } = await createDishCategory({
            variables: {
              input: {
                bizId: restaurantId,
                title: category,
                description: ''
              }
            }
          });
          if (!newCategory) {
            throw Error('Error in adding Category.');
          }

          categoryId = newCategory.id;
        }
      }

      let input: any = null;

      if (dishType === 'REGULAR' || dishType === 'TABLE_SERVICE') {
        input = {
          bizId: restaurantId,
          status,
          tags,
          title: dishName,
          categoryId,
          description: description,
          price,
          extraGroups,
          imageIds: imageId !== '' ? [imageId] : [],
          isReward,
          isAlcohol,
          isDiscounted,
          rewardPoints: points,
          updateOriginalImage: imageId !== '' ? true : undefined
        };
      }

      // if (dishType === 'PIZZA') {
      //   input = {
      //     bizId: restaurantId,
      //     imageIds: imageId !== '' ? [imageId] : []
      //   };
      // }

      let response = null;

      if (dishId && !copying) {
        input.id = dishId;
        response = await updateDish({
          variables: {
            input
          }
        });
      } else {
        response = await createDish({
          variables: {
            input
          }
        });
      }

      if (response) {
        const feedbackText = response.data && response.data.createDish ? 'Dish created successfully' : 'Dish updated successfully';

        snackbar.enqueueSnackbar(feedbackText, {
          variant: 'success'
        });

        if (!dishId && !copying) {
          clearImage();
        }

        if (redirectToMenu) {
          history.push(`/${HOME}/${restaurant}?${CATEGORY}=${categoryId}`);
        } else {
          if (!copying) {
            resetForm();
          }
        }
      } else {
        snackbar.enqueueSnackbar('Failed to create new dish', {
          variant: 'error'
        });
      }
      setCopying(false);
      setSubmitting(false);
    } catch (e) {
      snackbar.enqueueSnackbar('Error in creating dish', {
        variant: 'error'
      });
      setCopying(false);
      setSubmitting(false);
    }
  };

  const onDishImageCrop = (filename: string, data: string) => {
    setDishImage({
      filename,
      imageFiledata: data,
      hasError: false
    });
  };

  const clearImage = () => {
    const { removeImage } = uploadImageRef.current || {};
    if (removeImage) removeImage();

    setDishImage({
      filename: null,
      imageFiledata: null,
      hasError: true
    });
  };

  return (
    <Formik initialValues={intialFormValues} onSubmit={_onSubmit} validationSchema={validations} enableReinitialize={true}>
      {({ values, handleSubmit, handleChange, setFieldValue, errors, touched, handleBlur }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box padding={1} bgcolor="white">
              <Grid container={true} alignItems="center">
                <Grid item={true} xs={12} lg={12}>
                  <Box textAlign="right">
                    <FormControlLabel className={classes.formControls} control={<Switch name="visible" checked={values.visible} />} label="Show" onChange={handleChange} />
                    {!isEmpty(rewardSetting) && (
                      <FormControlLabel className={classes.formControls} control={<Switch name="isReward" checked={values.isReward} />} label="Reward Item" onChange={handleChange} />
                    )}
                    <FormControlLabel className={classes.formControls} control={<Switch name="popular" checked={values.popular} />} label="Popular" onChange={handleChange} />
                    <FormControlLabel className={classes.formControls} control={<Switch name="isAlcohol" checked={values.isAlcohol} />} label="Contains Alcohol" onChange={handleChange} />
                    <FormControlLabel
                      className={classes.formControls}
                      control={<Switch name="isDiscounted" checked={values.isDiscounted} />}
                      label="Discount"
                      onChange={(e: any) => {
                        const prices = values.prices.map((price) => {
                          price.isDiscounted = e.target.checked ? true : false;
                          return price;
                        });
                        values.prices = prices;
                        handleChange(e);
                      }}
                    />

                    <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => setredirectToMenu(true)}>
                      Save
                    </Button>

                    {!dishId && (
                      <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
                        Save +
                      </Button>
                    )}

                    <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<FileCopyIcon />} onClick={() => setCopying(true)}>
                      Copy
                    </Button>

                    <CancelBtn className={classes.formControls} restaurantId={restaurantId ? restaurantId : ''} touched={isEmpty(touched) ? false : true} />
                  </Box>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container={true} spacing={2}>
                <Grid item={true} lg={5} xl={5} md={5} sm={5}>
                  <Box marginBottom={3}>
                    <TextField
                      name="dishName"
                      value={values.dishName}
                      variant="outlined"
                      label="Dish Name"
                      fullWidth={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getIn(touched, 'dishName') && !!getIn(errors, 'dishName')}
                    />
                  </Box>
                  {values.isReward && !isEmpty(rewardSetting) && (
                    <Box marginBottom={3}>
                      <TextField
                        name="rewardPoints"
                        variant="outlined"
                        label="Reward Point"
                        fullWidth={true}
                        value={values.rewardPoints}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Box>
                  )}

                  <FieldArray
                    name="prices"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.prices &&
                          values.prices.length &&
                          values.prices.map((price, index) => (
                            <Box display="flex" key={index} marginBottom={1} justifyContent="flex-start" alignItems="center">
                              <Box component="span" marginRight={1}>
                                <TextField
                                  name={`prices[${index}].size`}
                                  variant="outlined"
                                  label="Size"
                                  value={values.prices[index].size}
                                  onChange={handleChange}
                                  error={!!getIn(touched, `prices[${index}].size`) && !!getIn(errors, `prices[${index}].size`)}
                                />
                              </Box>
                              <Box marginRight={1}>
                                <CurrencyInputField
                                  name={`prices[${index}].price`}
                                  variant="outlined"
                                  label="Price"
                                  value={values.prices[index].price}
                                  onChange={(e: any) => {
                                    if (index === 0) values.rewardPoints = convertPriceToPoints(e.target.value, pricePointRatio);
                                    handleChange(e);
                                  }}
                                  touched={!!getIn(touched, `prices[${index}].price`)}
                                  error={!!getIn(touched, `prices[${index}].price`) && !!getIn(errors, `prices[${index}].price`)}
                                />
                              </Box>

                              {values.isDiscounted && (
                                <Box marginRight={1}>
                                  <CurrencyInputField
                                    name={`prices[${index}].discountAmount`}
                                    variant="outlined"
                                    label="Discount Amount"
                                    value={values.prices[index].discountAmount}
                                    onChange={(e: any) => {
                                      values.prices[index].discountPercent = getPercentageDecrease(e.target.value, values.prices[index].price);
                                      handleChange(e);
                                    }}
                                    error={!!getIn(touched, `prices[${index}].discountAmount`) && !!getIn(errors, `prices[${index}].discountAmount`)}
                                    helperText={
                                      !!getIn(touched, `prices[${index}].discountAmount`) && !!getIn(errors, `prices[${index}].discountAmount`) ? getIn(errors, `prices[${index}].discountAmount`) : ''
                                    }
                                  />
                                </Box>
                              )}

                              {values.isDiscounted && (
                                <Box marginRight={1}>
                                  <TextField
                                    name={`prices[${index}].discountPercent`}
                                    variant="outlined"
                                    label="Discount Percent"
                                    value={values.prices[index].discountPercent}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: <span>%</span>
                                    }}
                                  />
                                </Box>
                              )}

                              {values.prices.length > 1 && dishType === 'REGULAR' && (
                                <Fab className={classes.deleteIcon} color="primary" size="small" onClick={() => arrayHelpers.remove(index)}>
                                  <DeleteIcon />
                                </Fab>
                              )}
                            </Box>
                          ))}
                        {dishType === 'REGULAR' && (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              arrayHelpers.push({
                                size: '',
                                price: '',
                                discountAmount: '',
                                discountPercent: '-',
                                isDiscounted: values.isDiscounted ? true : false
                              })
                            }>
                            Add new size
                          </Button>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item={true} sm={4} xs={12}>
                  <Box marginBottom={3}>
                    <CategoriesField
                      onChange={(value: string) => setFieldValue('category', value)}
                      onBlur={handleBlur}
                      error={!!getIn(touched, 'category') && !!getIn(errors, 'category')}
                      value={values.category}
                      setCategories={setCategories}
                    />
                  </Box>
                  <Box marginBottom={3}>
                    <TextField
                      name="description"
                      value={values.description}
                      variant="outlined"
                      label="Description"
                      fullWidth={true}
                      multiline={true}
                      rows={8}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getIn(touched, 'description') && !!getIn(errors, 'description')}
                    />
                  </Box>
                </Grid>
                <Grid item={true} sm={3} xs={12}>
                  <UploadImage ref={uploadImageRef} width="100%" height={250} onCrop={onDishImageCrop} initialValue={dishImageInitialValue} />
                </Grid>
              </Grid>

              <Box paddingTop={1}>
                <AddDishOptionDialog
                  setFieldValue={setFieldValue}
                  dishOptions={values.dishOptions}
                  component={
                    <Button color="primary" variant="contained" startIcon={<AddIcon />}>
                      Add Extra Choice
                    </Button>
                  }
                />
                <DishChoice values={values.dishOptions} setFieldValue={setFieldValue} />
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default RegularDishAddOrEditPage;
