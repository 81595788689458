import gql from 'graphql-tag';

export const updateDishDisplayOrderMutation = gql`
  mutation updateDishDisplayOrder($input: UpdateDishDisplayOrderInput!) {
    updateDishDisplayOrder(input: $input)
  }
`;

export const updateDishCategoryOrderMutation = gql`
  mutation updateDishCategoryDisplayOrder($input: UpdateDishCategoryDisplayOrderInput!) {
    updateDishCategoryDisplayOrder(input: $input)
  }
`;

export const createDishMutation = gql`
  mutation createDish($input: CreateDishInput!) {
    createDish(input: $input) {
      id
      title
      category {
        id
        title
      }
      status
      isReward
      rewardPoints
      tags
      type
      description
      price {
        id
        size
        isDefault
        price {
          currencyCode
          intValue
          shift
        }
      }
      images {
        id
        md {
          url
        }
      }
      extraGroups {
        id
        title
        minSelect
        maxSelect
        multiQtyPerItem
        isOptional
        items {
          id
          title
          price {
            intValue
            shift
            currencyCode
          }
        }
      }

      pizzaBasePrice {
        id
        size {
          id
          title
        }
        isDefault
        price {
          intValue
          shift
        }
      }
      pizzaCrust {
        id
        partId
        title
        description
      }
      pizzaSauce {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
      pizzaCheese {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
      pizzaToppingGroups {
        id
        title
        description
        maxSelect
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
    }
  }
`;

export const updateDishMutation = gql`
  mutation updateDish($input: UpdateDishInput!) {
    updateDish(input: $input) {
      id
      title
      category {
        id
        title
      }
      status
      isAlcohol
      isDiscounted
      isReward
      rewardPoints
      tags
      type
      description
      price {
        id
        size
        isDefault
        price {
          currencyCode
          intValue
          shift
        }
        discountAmount
      }
      images {
        id
        md {
          url
        }
      }
      extraGroups {
        id
        title
        minSelect
        maxSelect
        multiQtyPerItem
        isOptional
        items {
          id
          title
          price {
            intValue
            shift
            currencyCode
          }
        }
      }

      pizzaBasePrice {
        id
        size {
          id
          title
        }
        isDefault
        price {
          intValue
          shift
        }
      }
      pizzaCrust {
        id
        partId
        title
        description
      }
      pizzaSauce {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
      pizzaCheese {
        id
        title
        description
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
      pizzaToppingGroups {
        id
        title
        description
        maxSelect
        items {
          id
          partId
          groupPartId
          title
          description
          preset
        }
      }
    }
  }
`;

export const deleteDishMutation = gql`
  mutation deleteDish($input: DeleteDishMutation!) {
    deleteDish(input: $input)
  }
`;

export const createDishCategoryMutation = gql`
  mutation createDishCategory($input: CreateDishCategoryInput!) {
    createDishCategory(input: $input) {
      id
      title
    }
  }
`;

export const deleteDishCategoryMutation = gql`
  mutation deleteDishCategory($input: DeleteDishCategoryInput!) {
    deleteDishCategory(input: $input)
  }
`;

export const updateDishCategoryMutation = gql`
  mutation updateDishCategory($input: UpdateDishCategoryInput!) {
    updateDishCategory(input: $input) {
      id
      title
    }
  }
`;
