import { gql } from 'apollo-boost';

export default gql`
  query getRestaurantsByOwner($input: GetRestaurantsByOwnerInput) {
    getRestaurantsByOwner(input: $input) {
      edges {
        node {
          id
          type
          name
          logo {
            id
            url
          }
        }
      }
    }
  }
`;
