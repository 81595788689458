import { map, reduce } from 'lodash';
import { convertPrice, convertPriceTo, getPercentageDecrease } from 'util/number';
import { IFormValues } from './types';
import Big from 'big.js';

export const convertResponseToFormValues = (response: any) => {
  const output: IFormValues = {
    dishName: '',
    visible: true,
    isReward: false,
    isDiscounted: false,
    isAlcohol: false,
    popular: false,
    category: '',
    description: '',
    dishOptions: [],
    prices: [
      {
        size: 'Regular',
        price: '',
        discountAmount: '',
        discountPercent: '-',
        isDiscounted: false
      }
    ],
    rewardPoints: 0,
    dishType: 'REGULAR'
  };

  if (response) {
    const { title, category, status, isReward, isAlcohol, isDiscounted, rewardPoints, description, price, extraGroups, tags, type } = response;

    const dishName = title || '';

    const dishCategory = category.title || '';
    const dishDescription = description || '';
    if (type === 'REGULAR' || type === 'TABLE_SERVICE') {
      const prices = map(price, ({ id, size, price: _price, discountAmount }) => {
        const _size = size || '';
        const { intValue, shift } = _price;

        const convertedPrice = convertPrice(intValue, shift);

        return {
          id,
          size: _size,
          price: convertedPrice,
          discountAmount: discountAmount ? convertPriceTo(discountAmount, 'DOLLAR') : '',
          discountPercent: discountAmount ? getPercentageDecrease(convertPriceTo(discountAmount, 'DOLLAR'), convertedPrice) : '-',
          isDiscounted: isDiscounted ? true : false
        };
      });

      const dishOptions = map(extraGroups, ({ id: dishOptionId, title: dishOptionTitle, minSelect, maxSelect, isOptional, multiQtyPerItem, items }: any, index) => {
        const _dishOptionTitle = dishOptionTitle || '';
        const _items = map(items, ({ id: dishItemId, title: itemTitle, price: itemPrice }) => {
          const _itemTitle = itemTitle || '';
          const { intValue, shift } = itemPrice;

          return {
            id: dishItemId,
            title: _itemTitle,
            price: convertPrice(intValue, shift)
          };
        });

        return {
          id: dishOptionId,
          title: _dishOptionTitle,
          min: minSelect,
          max: maxSelect,
          isOptional: isOptional ? 'true' : 'false',
          isMultiQnty: multiQtyPerItem ? 'true' : 'false',
          items: _items
        };
      });

      output.prices = prices;
      output.dishOptions = dishOptions as any;
      output.rewardPoints = rewardPoints;
    }

    if (tags && tags.length && tags.indexOf('POPULAR') > -1) {
      output.popular = true;
    }

    output.dishName = dishName;
    output.description = dishDescription;
    output.category = dishCategory;

    output.visible = status === 'ACTIVE';
    output.dishType = type;
    output.isReward = isReward;
    output.isAlcohol = isAlcohol;
    output.isDiscounted = !!isDiscounted;
  }

  return output;
};

export const convertPriceToPoints = (price: string, pricePointRatio: string | number) => {
  let points = Big(0);

  if (price) {
    points = Big(price)
      .round(0, 3)
      .mul(pricePointRatio);
  }

  return parseInt(points.toString());
};
