import React, { useState, useEffect } from 'react';
import { useStore } from 'store';
import { Typography, Box, Grid, FormControlLabel, Divider, Checkbox, Switch, Button, TextField, Tab } from '@material-ui/core';
import { ISize, ICrust, IPizzaToppingGroup } from 'pages/Home/RestaurantDashboard/PizzaConfiguration/types';
import { IPizzaDish } from './types';
import { useFormik, getIn } from 'formik';
import { useParams } from 'react-router-dom';
import { useStyles } from './style';
import SaveIcon from '@material-ui/icons/Save';
import CancelBtn from 'components/CancelBtn';
import { isEmpty, map, filter } from 'lodash';
import UploadImage from 'components/UploadImage';
import CategoriesField from '../RegularDish/CategoriesField';
import { ICategory } from '../RegularDish/types';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import StyledTabs from 'components/StyledTabs';
import CrustTable from './Crust';
import PizzaGroupTable from './PizzaGroup';
import validations from './validations';
import { useSnackbar } from 'notistack';
import { useCreateDishMutation, useUpdateDishMutation, useCreateDishCategory } from 'graphql/hooks/dish.hooks';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useHistory } from 'react-router-dom';
import { convertResponseToFormValues } from './utils';
import { CATEGORY, HOME } from 'util/strings';

interface IProps {
  dish: any;
  sizes: ISize[];
  crusts: ICrust[];
  sauces: IPizzaToppingGroup[];
  cheeses: IPizzaToppingGroup[];
  toppings: IPizzaToppingGroup[];
}

type ITabOption = 'Crust' | 'Sauce' | 'Cheese' | 'Toppings';

const PizzaDishAddOrEditPage = ({ dish, sizes, crusts, sauces, cheeses, toppings }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurant }
  } = useStore();

  const initialValues: IPizzaDish = {
    dishName: '',
    visible: true,
    popular: false,
    category: '',
    description: '',
    dishType: 'PIZZA',
    pizzaBasePrice: map(sizes, ({ id }) => {
      return {
        sizeId: id ? id : '',
        price: '0.00'
      };
    }),
    pizzaCrustIds: [],
    pizzaDefaultCrustId: '',
    pizzaCheese: [],
    pizzaSauce: [],
    pizzaToppings: []
  };

  const { dishId } = useParams() as any;

  const { createDish } = useCreateDishMutation();

  const { updateDish } = useUpdateDishMutation();

  const { createDishCategory } = useCreateDishCategory();

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();

  const snackbar = useSnackbar();

  const [redirectToMenu, setredirectToMenu] = useState(false);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const uploadImageRef: any = React.useRef(null);

  const [dishImage, setDishImage] = useState<{ filename: string | null; imageFiledata: string | null; hasError: boolean }>({ filename: null, imageFiledata: null, hasError: true });

  const [dishImageInitialValue, setDishImageInitialValue] = useState<string | undefined>(undefined);

  const [activeTab, setActiveTab] = useState<ITabOption>('Crust');

  const history = useHistory();

  const [initialFormValues, setInitialFormValues] = useState<IPizzaDish>(initialValues);

  const [copying, setCopying] = useState(false);

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: (formValues) => handleSubmit(formValues),
    validationSchema: validations
  });

  useEffect(() => {
    if (dish) {
      const output = convertResponseToFormValues(dish);

      const { images } = dish || { images: [] };

      if (images && images.length) {
        const {
          md: { url }
        } = images[0];

        setDishImageInitialValue(url);
      }

      setInitialFormValues(output);
    }
  }, [dish]);

  const handleSubmit = async (formValues: IPizzaDish) => {
    const { dishName, visible, popular, category, description, dishType, pizzaBasePrice, pizzaCrustIds, pizzaCheese, pizzaSauce, pizzaToppings, pizzaDefaultCrustId } = formValues;

    const status = visible ? 'ACTIVE' : 'HIDDEN';

    const tags = popular ? ['POPULAR'] : [];

    const basePrices = map(pizzaBasePrice, ({ price, sizeId }, index) => {
      let isDefault = false;
      if (index === 0) {
        isDefault = true;
      }
      return {
        sizeId,
        isDefault,
        price: {
          currencyCode: 'usd',
          strValue: price
        }
      };
    });

    try {
      /* Image upload */
      let imageId = '';

      const { filename } = dishImage;

      if (filename) {
        const { data: uploadImageResponse } = await createImageUploadUrl({
          variables: {
            input: {
              usage: 'DISH_IMG',
              fileName: filename
            }
          }
        });

        if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
          const { id, url } = uploadImageResponse.createImageUploadUrl;
          imageId = id;
          if (dishImage.imageFiledata) {
            await uploadImage(url, dishImage.imageFiledata);
          } else {
            throw Error('Image data not available');
          }
        } else {
          throw Error('Image Upload error');
        }
      } else {
        if (dishImageInitialValue) {
          const { images } = dish;

          const { id } = images[0];

          imageId = id;
        }
      }

      /* Category selection */
      // Check if category present
      let categoryId = '';
      const oldCategory = filter(categories, (catg: ICategory) => catg.title === category)[0];

      if (oldCategory) {
        categoryId = oldCategory.id;
      } else {
        // If category not present create new category
        const {
          data: { createDishCategory: newCategory }
        } = await createDishCategory({
          variables: {
            input: {
              bizId: restaurant,
              title: category,
              description: description
            }
          }
        });
        if (!newCategory) {
          throw Error('Error in adding Category.');
        }

        categoryId = newCategory.id;
      }

      let sauceMaxSelect = 0;

      const sauceInput = map(pizzaSauce, ({ groupId, items }) => {
        sauceMaxSelect += items.length;
        return {
          groupPartId: groupId,
          items
        };
      });

      let cheeseMaxSelect = 0;

      const cheeseInput = map(pizzaCheese, ({ groupId, items }) => {
        cheeseMaxSelect += items.length;
        return {
          groupPartId: groupId,
          items
        };
      });

      const toppingsInput = map(pizzaToppings, ({ groupId, maxSelect, items }) => {
        return {
          maxSelect: items.length,
          group: {
            groupPartId: groupId,
            items
          }
        };
      });

      /* API input */
      const input: any = {
        bizId: restaurant,
        status,
        tags,
        type: dishType,
        title: dishName,
        categoryId,
        description: description,
        imageIds: imageId !== '' ? [imageId] : [],
        updateOriginalImage: imageId !== '' ? true : undefined,
        pizzaBasePrice: basePrices,
        pizzaCrustIds,
        pizzaDefaultCrustId,
        pizzaSauce: {
          maxSelect: sauceMaxSelect,
          groups: sauceInput
        },
        pizzaCheese: {
          maxSelect: cheeseMaxSelect,
          groups: cheeseInput
        },
        pizzaToppings: toppingsInput
      };

      let response = null;

      if (dishId && !copying) {
        input.id = dishId;
        response = await updateDish({
          variables: {
            input
          }
        });
      } else {
        response = await createDish({
          variables: {
            input
          }
        });
      }

      if (response) {
        const feedbackText = response.data && response.data.createDish ? 'Dish created successfully' : 'Dish updated successfully';

        snackbar.enqueueSnackbar(feedbackText, {
          variant: 'success'
        });

        if (!dishId && !copying) {
          clearImage();
        }
        if (redirectToMenu) {
          history.push(`/${HOME}/${restaurant}?${CATEGORY}=${categoryId}`);
        } else {
          if (!copying) {
            formik.resetForm();
          }
        }
        setCopying(false);
      } else {
        snackbar.enqueueSnackbar('Failed to create new dish', {
          variant: 'error'
        });
      }
    } catch (e) {
      console.log(e);
      snackbar.enqueueSnackbar('Error in creating dish', {
        variant: 'error'
      });
    }
  };

  const onDishImageCrop = (filename: string, data: string) => {
    setDishImage({
      filename,
      imageFiledata: data,
      hasError: false
    });
  };

  const clearImage = () => {
    const { removeImage } = uploadImageRef.current || {};
    if (removeImage) removeImage();

    setDishImage({
      filename: null,
      imageFiledata: null,
      hasError: true
    });
  };

  const { setFieldValue, handleChange, handleBlur, touched, errors, values } = formik;

  return (
    <Box padding={1}>
      <Grid container={true} alignItems="center">
        <Grid item={true} xs={4} lg={4}>
          <Typography variant="h5">{dishId ? 'Edit Dish' : 'New Dish'} </Typography>
        </Grid>
        <Grid item={true} xs={8} lg={8}>
          <Box textAlign="right">
            <>
              <FormControlLabel className={classes.formControls} control={<Switch name="visible" checked={values.visible} />} label="Show" onChange={handleChange} />
              <FormControlLabel className={classes.formControls} control={<Checkbox color="primary" name="popular" checked={values.popular} onChange={handleChange} />} label="Popular" />
            </>

            <Button
              className={classes.formControls}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                setredirectToMenu(true);
                formik.submitForm();
              }}>
              Save
            </Button>

            {!dishId && (
              <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => formik.submitForm()}>
                Save +
              </Button>
            )}
            <Button
              className={classes.formControls}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                setCopying(true);
                formik.submitForm();
              }}>
              Copy
            </Button>
            <CancelBtn className={classes.formControls} restaurantId={restaurant} touched={isEmpty(touched) ? false : true} />
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container={true} spacing={2}>
        <Grid item={true} lg={5} xl={5} md={5} sm={5}>
          <Box marginBottom={3}>
            <TextField
              name="dishName"
              value={values.dishName}
              variant="outlined"
              label="Dish Name"
              fullWidth={true}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!getIn(touched, 'dishName') && !!getIn(errors, 'dishName')}
            />
            {map(sizes, ({ id, title }: ISize) => {
              let basePriceIndex: number | null = null;

              const basePrice = filter(values.pizzaBasePrice, ({ sizeId }, index: number) => {
                if (sizeId === id) {
                  basePriceIndex = index;
                  return true;
                }
                return false;
              });

              return (
                <Box key={id} display="flex" alignItems="center" paddingY={1}>
                  <Box paddingRight={1}>
                    <TextField
                      type="text"
                      variant="outlined"
                      value={title}
                      InputProps={{
                        readOnly: true
                      }}
                      fullWidth={true}
                    />
                  </Box>
                  <Box paddingX={1}>
                    <CurrencyInputField
                      name={`pizzaBasePrice[${basePriceIndex}].price`}
                      variant="outlined"
                      value={basePrice.length ? basePrice[0].price : ''}
                      disabled={!basePrice.length}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={!!getIn(touched, `pizzaBasePrice[${basePriceIndex}].price`)}
                      error={!!getIn(touched, `pizzaBasePrice[${basePriceIndex}].price`) && !!getIn(errors, `pizzaBasePrice[${basePriceIndex}].price`)}
                    />
                  </Box>
                  <Box>
                    <Checkbox
                      color="primary"
                      className={classes.checkbox}
                      checked={!!basePrice.length}
                      onChange={(e: any, checked) => {
                        if (checked) {
                          setFieldValue('pizzaBasePrice', [...values.pizzaBasePrice, { sizeId: id, price: '0.00' }], true);
                        } else {
                          setFieldValue(
                            'pizzaBasePrice',
                            filter(values.pizzaBasePrice, ({ sizeId }) => sizeId !== id)
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item={true} sm={4} xs={12}>
          <Box marginBottom={3}>
            <CategoriesField
              onChange={(value: string) => setFieldValue('category', value)}
              onBlur={handleBlur}
              error={!!getIn(touched, 'category') && !!getIn(errors, 'category')}
              value={values.category}
              setCategories={setCategories}
            />
          </Box>
          <Box marginBottom={3}>
            <TextField
              name="description"
              value={values.description}
              variant="outlined"
              label="Description"
              fullWidth={true}
              multiline={true}
              rows={8}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!getIn(touched, 'description') && !!getIn(errors, 'description')}
            />
          </Box>
        </Grid>
        <Grid item={true} sm={3} xs={12}>
          <UploadImage ref={uploadImageRef} width="100%" height={250} onCrop={onDishImageCrop} initialValue={dishImageInitialValue} />
        </Grid>
      </Grid>
      <Box>
        <StyledTabs value={activeTab} onChange={(_e: any, val: any) => setActiveTab(val)}>
          <Tab label="Crust" value="Crust" />
          <Tab label="Sauce" value="Sauce" />
          <Tab label="Cheese" value="Cheese" />
          <Tab label="Toppings" value="Toppings" />
        </StyledTabs>
        <Divider />
        {activeTab === 'Crust' && <CrustTable crusts={crusts} setFieldValue={setFieldValue} pizzaCrustIds={values.pizzaCrustIds} defaultCrust={values.pizzaDefaultCrustId} />}
        {activeTab === 'Sauce' && <PizzaGroupTable groups={sauces} pizzaGroups={values.pizzaSauce} setFieldValue={setFieldValue} field="pizzaSauce" />}
        {activeTab === 'Cheese' && <PizzaGroupTable groups={cheeses} pizzaGroups={values.pizzaCheese} setFieldValue={setFieldValue} field="pizzaCheese" />}
        {activeTab === 'Toppings' && <PizzaGroupTable groups={toppings} pizzaGroups={values.pizzaToppings} setFieldValue={setFieldValue} field="pizzaToppings" />}
      </Box>
    </Box>
  );
};

export default PizzaDishAddOrEditPage;
