import { gql } from 'apollo-boost';

export default gql`
  query getOrderByOrderNumber($input: GetOrderByOrderNumberInput!) {
    getOrderByOrderNumber(input: $input) {
      id
      status
      deliveryProvider
      deliveryMode
      deliveryAddress {
        text
      }
      expectTime
      orderNumber
      createdAt
      timeLines {
        msg
        status
      }
      buyer {
        uid
        phoneNumber
        email
        displayName
      }
      orderEmail
      orderPhoneNumber
      paymentType
      type
      subTotal {
        intValue
        shift
      }
      total {
        intValue
        shift
      }
      couponDiscount {
        intValue
        shift
      }
      coupon {
        id
        code
      }
      tax {
        intValue
        shift
      }
      lokobeeFee {
        intValue
        shift
      }
      deliveryFee {
        intValue
        shift
      }
      deliveryRequest {
        doordashClassicPayload {
          fee
        }
      }
      tableName
      partySize
      items {
        dishId
        count
        dishType
        isReward
        isDiscounted
        points
        dishTitle

        dishPrice {
          price {
            intValue
            shift
          }
          size
          discountAmount
        }

        pizzaBasePrice {
          id
          size {
            title
          }
          price {
            intValue
            shift
          }
          discountAmount
        }
        pizzaCrust {
          crustId
          title
          price {
            intValue
            shift
          }
        }

        pizzaSauces {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaCheeses {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaToppings {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }
      }
    }
  }
`;
