import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Divider, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useFormik } from 'formik';
import { useStyles } from './style';
import { useStore } from 'store';
import { useGetRestaurantById, useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

interface IInitialValueType {
  announcement: string;
}

/**
 * Announcment page
 *
 * This screen allows user to configure announcement for the restaurant.
 */
const Announcement: React.FC = () => {
  const classes = useStyles();

  const snackbar = useSnackbar();

  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'ANNOUNCEMENT');

  const { updateRestaurant } = useUpdateRestaurantMutation('ANNOUNCEMENT');

  const resData = data ? data.ownerGetRestaurantById : {};
  const announcement = resData?.announcement;
  const initialValues: IInitialValueType = {
    announcement: announcement?.text || ''
  };

  const [disableButton, setDisableButton] = useState(true);

  const onSubmit = async (values: IInitialValueType) => {
    setDisableButton(true);
    const response = await updateRestaurant({
      variables: {
        input: {
          id: restaurant,
          announcement: values.announcement
        }
      }
    });
    if (!response.errors) {
      snackbar.enqueueSnackbar('Announcement updated successfully !', {
        variant: 'success'
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit
  });

  const { values, handleChange, handleSubmit } = formik;

  const _onChange = (event: React.ChangeEvent) => {
    setDisableButton(false);
    handleChange(event);
  };

  if (!data || !data.ownerGetRestaurantById) {
    return null;
  }

  return (
    <Box bgcolor="white" padding={1}>
      <form onSubmit={handleSubmit}>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={4}>
            <Typography variant="h5">Announcement</Typography>
          </Grid>
          <Grid item={true} xs={8}>
            <Box textAlign="right">
              <Button className={classes.formControls} type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disabled={disableButton}>
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box width="70%" margin="auto">
          <TextField
            name="announcement"
            value={values.announcement}
            onChange={_onChange}
            variant="outlined"
            label="Announcement"
            fullWidth={true}
            multiline={true}
            rows={8}
            InputProps={{
              endAdornment: (
                <Box alignSelf="flex-start">
                  <CloseIcon
                    cursor="pointer"
                    onClick={() => {
                      formik.setValues({ announcement: '' });
                      setDisableButton(false);
                    }}
                  />
                </Box>
              )
            }}
          />
        </Box>
      </form>
    </Box>
  );
};

export default Announcement;
