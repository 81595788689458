import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tabs, Tab, Box, Button, Divider, Container, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RestaurantCategories from 'components/RestaurantCategories';
import RestaurantInfoDisplay from './RestaurantInfoDisplay';
import { MenuEmptyScreen } from 'components/EmptyScreen';
import DishList from './DishList';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useStyles } from './style';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'store';
import Sortable from 'components/Sortable';
import DishSorting from './DishSorting';
import { useGetDishCategories, useUpdateDishCategoryOrderMutation, useGetDishesQuery } from 'graphql/hooks/dish.hooks';
import { map, filter, chain } from 'lodash';
import { ISortableData } from 'generated/custom';
import { getUrlQuery } from './util';

const tabs = ['ACTIVE', 'HIDDEN', 'DISCOUNTED'];
const mapTabs: any = {
  ACTIVE: 'visible',
  HIDDEN: 'hidden',
  DISCOUNTED: 'discounted'
};

const Main = () => {
  const classes = useStyles();

  const history = useHistory();

  const location = useLocation();

  const theme = useTheme();

  const activeCategory = getUrlQuery(location, 'category') || 'All';

  const { restaurantId } = useActiveRestaurant();

  const [activeTab, setActiveTab] = useState(0);

  const visibility = tabs[activeTab];

  const { dishes: resturantDishes, loading } = useGetDishesQuery({ restaurantId });

  const [dishCategories, setDishCategories] = useState<ISortableData[] | null>(null);

  const [dishLength, setDishLength] = useState({
    active: 0,
    hidden: 0,
    discounted: 0
  });

  const { data: dishCategoriesData } = useGetDishCategories();

  const { updateDishCategoryOrder } = useUpdateDishCategoryOrderMutation();

  React.useEffect(() => {
    const activeDishes = filter(resturantDishes, ({ status }) => status === 'ACTIVE');

    const activeDishLength =
      activeCategory === 'All'
        ? activeDishes.length
        : filter(activeDishes, ({ category }) => {
            const { id } = category;
            return id === activeCategory;
          }).length;

    const hiddedDishes = filter(resturantDishes, ({ status }) => status === 'HIDDEN');

    const hiddenDishLength =
      activeCategory === 'All'
        ? hiddedDishes.length
        : filter(hiddedDishes, ({ category }) => {
            const { id } = category;
            return id === activeCategory;
          }).length;

    const discountedDishes = filter(resturantDishes, ({ isDiscounted }) => isDiscounted === true);

    const discountedDishesLenght =
      activeCategory === 'All'
        ? discountedDishes.length
        : filter(discountedDishes, ({ category }) => {
            const { id } = category;
            return id === activeCategory;
          }).length;

    setDishLength({
      active: activeDishLength,
      hidden: hiddenDishLength,
      discounted: discountedDishesLenght
    });
  }, [activeCategory, resturantDishes, setDishLength]);

  useEffect(() => {
    if (dishCategoriesData && dishCategoriesData.getDishCategoriesOfRestaurant) {
      const { getDishCategoriesOfRestaurant } = dishCategoriesData;

      const data = map(getDishCategoriesOfRestaurant, ({ id: categoryId, title: categoryTitleArray }, index: number) => {
        return { id: categoryId, title: categoryTitleArray || '' };
      });

      setDishCategories(data);
    }
  }, [dishCategoriesData]);

  const _setActiveTab = (_event: React.ChangeEvent<{}>, index: number) => {
    setActiveTab(index);
  };

  const onSortSave = async (data: ISortableData[]) => {
    const categoriesId = map(data, ({ id }) => {
      return id;
    });

    const response = await updateDishCategoryOrder({
      variables: {
        input: {
          bizId: restaurantId,
          catIds: categoriesId
        }
      }
    });

    if (response && response.data) {
      return true;
    }

    return false;
  };

  const getMenu = () => {
    if (resturantDishes) {
      let dishArray;

      if (activeTab === 2) {
        // filtering discounted dishes
        dishArray = chain(resturantDishes)
          .filter(({ isDiscounted }) => isDiscounted === true)
          .value();
      } else {
        // filtering active, hidden dishes
        dishArray = chain(resturantDishes)
          .filter(({ status }) => status === visibility)
          .value();
      }

      if (activeCategory !== 'All') {
        dishArray = dishArray.filter(({ category }: any) => {
          const { id } = category;
          return id === activeCategory;
        });
      }

      if (dishArray && dishArray.length) {
        return <DishList dishes={dishArray} showActions={true} />;
      } else {
        return <Typography>No {mapTabs[visibility]} dishes to show.</Typography>;
      }
    }
  };

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const {
    state: {
      restaurantMenu: { categories }
    }
  } = useStore();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Container disableGutters maxWidth="lg">
      <RestaurantInfoDisplay />
      {!loading && categories.length === 0 && (
        <MenuEmptyScreen
          onAddDish={() => {
            history.push(`/home/${restaurantId}/new-dish`);
          }}
          onAddPizzaDish={() => {
            history.push(`/home/${restaurantId}/new-dish`, { dishType: 'PIZZA' });
          }}
        />
      )}

      <Grid className={loading || categories.length === 0 ? classes.hide : ''} container={true} alignItems="stretch" spacing={isDesktop ? 3 : 0}>
        <Grid item={true} xs={12} md={3}>
          <Box boxShadow={2}>
            <Box display="flex" justifyContent="space-around" textAlign="center" paddingY={2} hidden={!isDesktop}>
              <Typography variant="h6">Categories</Typography>
              {dishCategories && dishCategories.length > 1 && <Sortable dialogHeaderText="Dish Categories" buttonText="Rearrange" data={dishCategories} onSave={onSortSave} />}
            </Box>
            <RestaurantCategories data={dishCategories} />
          </Box>
        </Grid>
        <Grid item={true} xs={12} md={9}>
          <Box boxShadow={2} padding={1}>
            <Box display="flex" alignItems="center" className={classes.headerContainer}>
              <Box paddingX={1}>{visibility === 'ACTIVE' && <DishSorting visibility={visibility} />}</Box>
              <Box flexGrow={1} className={classes.tabsContainer}>
                <StyledTabs value={activeTab} onChange={_setActiveTab}>
                  <Tab label={'Visible (' + dishLength.active + ')'} id="0" />
                  <Tab label={'Hidden (' + dishLength.hidden + ')'} id="1" />
                  <Tab label={'Discounted (' + dishLength.discounted + ')'} id="2" />
                </StyledTabs>
              </Box>

              <Box paddingX={1}>
                <Link to={`/home/${restaurantId}/new-dish`}>
                  <Button variant="contained" color="primary" size="small" disableElevation={true} startIcon={<AddIcon fontSize="small" />}>
                    New Dish
                  </Button>
                </Link>
              </Box>
              <Box paddingX={1}>
                <Link to={{ pathname: `/home/${restaurantId}/new-dish`, state: { dishType: 'PIZZA' } }}>
                  <Button variant="contained" color="primary" size="small" disableElevation={true} startIcon={<AddIcon fontSize="small" />}>
                    Add pizza
                  </Button>
                </Link>
              </Box>
            </Box>
            <Divider className={classes.divider} />
            <Box paddingX={3} paddingY={4}>
              {getMenu()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Main;
