import React from 'react';
import { Box, Typography, Grid, Divider, Container } from '@material-ui/core';
import S3Image from 'components/S3Image';
import { RestaurantEmptyScreen } from 'components/EmptyScreen';
import AddIcon from '@material-ui/icons/Add';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../style';
import { useRestaurantList } from 'hooks/restaurant';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import DeleteRestaurant from './DeleteRestaurant';
import EditIcon from '@material-ui/icons/Edit';
import { useCreateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';

const RestaurantList: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { data } = useRestaurantList();

  const { createRestaurant } = useCreateRestaurantMutation();

  const redirectToNewRestaurantPage = async () => {
    try {
      const response = await createRestaurant({
        variables: {
          input: {
            type: 'REGULAR_RESTAURANT'
          }
        }
      });

      if (response && response.data) {
        const { createRestaurant } = response.data;
        const id = createRestaurant.id;

        const REDIRECT_URL = `/home/${id}/info`;

        history.push(REDIRECT_URL);
      }
    } catch (e) {
      console.log('[ERROR] Create restaurant failed');
    }
  };

  const redirectToRestaurantDashboard = (id: string) => {
    history.push(`/home/${id}`);
  };

  let count = 1;

  return (
    <Box marginTop={2} marginLeft={-2}>
      {data && data.length === 0 ? (
        <RestaurantEmptyScreen onAdd={redirectToNewRestaurantPage} />
      ) : (
        <Grid container={true} alignItems="stretch">
          {map(data, ({ id, name, logo }, index) => {
            const restaurantName = name || 'Unnamed Restaurant ' + count++;
            const { url } = logo || {};

            return (
              <Grid item={true} xs={12} sm={6} md={4} key={id} className={classes.addItemContainer}>
                <Box boxShadow={1} borderRadius={5}>
                  <Box width="100%" height="250px" margin={0} padding={0} className={classes.dataBox} onClick={() => redirectToRestaurantDashboard(id)}>
                    <S3Image src={url} key={id + index + 'image'} defaultImage={restaurantEmptyIcon} />
                  </Box>

                  <Box padding={2} bgcolor="white" display="flex">
                    <Typography variant="body1">{restaurantName}</Typography>
                    <Box flex="1" textAlign="right">
                      <EditIcon className={classes.editIcon} onClick={() => redirectToRestaurantDashboard(id)} />
                      <DeleteRestaurant id={id} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item={true} xs={12} sm={6} md={4} className={classes.addItemContainer} onClick={redirectToNewRestaurantPage}>
            <Box className={classes.addItemBox} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
              <AddIcon className={classes.addIcon} />
              <Typography variant="body1">Add Restaurant</Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default RestaurantList;
