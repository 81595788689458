import { Box, TextField, Typography, Button } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useStyles } from '../style';
import { useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import { IRestaurantBizCardInfo } from '../types';
import S3Image from 'components/S3Image';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import { FormikProps, useFormik } from 'formik';
import UploadImage from 'components/UploadImage';
import { FirebaseContext } from 'fbase';
import { useStore } from 'store';

interface IProps {
  initialValues: IRestaurantBizCardInfo;
  restaurantId: string;
  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
  isLokobeePartnerState: boolean;
}
const BusinessCardInfo = ({ initialValues, restaurantId, getUploadId, isLokobeePartnerState }: IProps) => {
  const classes = useStyles();
  const { firebase } = useContext(FirebaseContext);
  const { dispatch } = useStore();
  const { updateRestaurant } = useUpdateRestaurantMutation('BIZCARD');

  const formik: FormikProps<IRestaurantBizCardInfo> = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  const startLoader = () => {
    dispatch({
      type: 'LOADER_INCREMENT_COUNT'
    });
  };
  const endLoader = () => {
    dispatch({
      type: 'LOADER_DECREMENT_COUNT'
    });
  };
  const callToUpdateRestaurantBusinessCard = async (
    uploadId: string | null | undefined,
    bizCardBackSideMainSubTitle: string | undefined,
    bizCardBackSideMainTitle: string | undefined,
    bizBackBottomLine1: string | undefined,
    bizBackBottomLine2: string | undefined
  ) => {
    await updateRestaurant({
      variables: {
        input: {
          id: restaurantId,
          bizCardBackSideImg: uploadId,
          bizCardBackSideMainSubTitle: bizCardBackSideMainSubTitle,
          bizCardBackSideMainTitle: bizCardBackSideMainTitle,
          bizBackBottomLine1: bizBackBottomLine1,
          bizBackBottomLine2: bizBackBottomLine2
        }
      }
    });
  };
  const handleFormSubmit = async (formValues: IRestaurantBizCardInfo) => {
    try {
      let uploadId: string | null | undefined = '';
      const { backSideImgData } = formValues;
      console.log('backSideImgData', backSideImgData);
      if (backSideImgData) {
        if (backSideImgData.filename && backSideImgData.imageFiledata) {
          uploadId = await getUploadId(backSideImgData.filename, backSideImgData.imageFiledata);

          if (!uploadId || uploadId === '') {
            throw Error('Image Upload failed');
          }
        } else {
          uploadId = initialValues.bizCardBackSideImg && initialValues.bizCardBackSideImg.id ? initialValues.bizCardBackSideImg.id : undefined;
        }
      } else {
        if (initialValues && initialValues.bizCardBackSideImg && initialValues.bizCardBackSideImg.id) {
          uploadId = initialValues.bizCardBackSideImg && initialValues.bizCardBackSideImg.id ? initialValues.bizCardBackSideImg.id : undefined;
        }
      }
      if (backSideImgData) {
        startLoader();
        const imgPath = `/upload_image/${uploadId}`;
        firebase
          ?.getRealtimeDB()
          .ref(imgPath)
          .on('value', async (val) => {
            const backSideImgDbStatusValue = val.val();
            if (backSideImgDbStatusValue && backSideImgDbStatusValue.status === 'done') {
              callToUpdateRestaurantBusinessCard(uploadId, formValues.bizCardBackSideMainSubTitle, formValues.bizCardBackSideMainTitle, formValues.bizBackBottomLine1, formValues.bizBackBottomLine2);
              firebase
                ?.getRealtimeDB()
                .ref(imgPath)
                .off('value');
              endLoader();
            }
          });
      } else {
        callToUpdateRestaurantBusinessCard(uploadId, formValues.bizCardBackSideMainSubTitle, formValues.bizCardBackSideMainTitle, formValues.bizBackBottomLine1, formValues.bizBackBottomLine2);
      }
    } catch (e) {
      console.log(e);
      endLoader();
    }
  };
  const onCrop = (filename: string, imageFiledata: string) => {
    formik.setValues({
      ...formik.values,
      backSideImgData: {
        filename,
        imageFiledata
      }
    });
  };
  useEffect(() => {
    if (!formik.values.bizBackBottomLine1 && formik.values.bizBackBottomLine2 && formik.values.bizBackBottomLine2?.length > 0) {
      formik.setValues({
        ...formik.values,
        bizBackBottomLine2: ''
      });
    }
  }, [formik]);
  return (
    <form>
      <Box paddingY={1} marginBottom={1}>
        <Typography variant="h6"> Front Side</Typography>
      </Box>
      {formik.values.logo && (
        <Box display={'flex'} marginBottom={2} justifyContent="center">
          <Box width="50%" height="auto" marginBottom={2} padding={0}>
            <S3Image src={formik.values.logo.url} defaultImage={restaurantEmptyIcon} />
          </Box>
        </Box>
      )}
      <Box display={'flex'} marginBottom={2}>
        <Box marginRight={1} width="100%">
          <TextField className={classes.inputField} name="name" variant="outlined" label={'Restaurant Name'} value={formik.values.name} />
        </Box>
        <Box width="100%">
          <TextField className={classes.inputField} name="subName" variant="outlined" label={'Restaurant Sub Name'} value={formik.values.subName} />
        </Box>
      </Box>
      <Box width="100%" marginBottom={2}>
        <TextField className={classes.inputField} name="address" variant="outlined" label={'Address'} value={formik.values.address.text} />
      </Box>
      <Box display={'flex'} marginBottom={2}>
        <Box marginRight={1} width="100%">
          <TextField className={classes.inputField} name="phone" variant="outlined" label={'Phone No.'} value={formik.values.phone} />
        </Box>
        <Box width="100%">
          <TextField
            className={classes.inputField}
            name="customDomainUrl"
            variant="outlined"
            helperText={formik.values.customDomainUrl.length > 30 && `Website URL is too long to be printed on a business card`}
            label={'Website'}
            value={formik.values.customDomainUrl}
          />
        </Box>
      </Box>

      {isLokobeePartnerState && (
        <>
          <Box paddingY={1} marginBottom={2} justifyContent="space-between" alignItems={'center'} display="flex" flex={1} width="100%">
            <Typography variant="h6"> Back Side</Typography>
            <Button variant="contained" onClick={() => formik.submitForm()} color="primary">
              Save
            </Button>
          </Box>
          <Box width="100%" marginBottom={2}>
            <TextField
              className={classes.inputField}
              name="bizCardBackSideMainTitle"
              onChange={formik.handleChange}
              variant="outlined"
              label={'Main Title'}
              inputProps={{
                maxLength: 30
              }}
              value={formik.values.bizCardBackSideMainTitle}
            />
          </Box>
          <Box width="100%" marginBottom={2}>
            <TextField
              className={classes.inputField}
              name="bizCardBackSideMainSubTitle"
              onChange={formik.handleChange}
              variant="outlined"
              label={'Main Sub Title'}
              inputProps={{
                maxLength: 25
              }}
              value={formik.values.bizCardBackSideMainSubTitle}
            />
          </Box>
          <Box width="100%" marginBottom={2}>
            <TextField
              className={classes.inputField}
              name="bizBackBottomLine1"
              onChange={formik.handleChange}
              variant="outlined"
              label={'Bottom Line 1'}
              inputProps={{
                maxLength: 25
              }}
              value={formik.values.bizBackBottomLine1}
            />
          </Box>

          <Box width="100%" marginBottom={2}>
            <TextField
              className={classes.inputField}
              name="bizBackBottomLine2"
              onChange={formik.handleChange}
              variant="outlined"
              label={'Bottom Line 2'}
              inputProps={{
                maxLength: 25
              }}
              value={formik.values.bizBackBottomLine2}
            />
          </Box>

          <Box marginBottom={1}>
            <Typography variant="body1"> Image for backside (click to update): </Typography>
          </Box>

          <Box marginBottom={2} display={'flex'} justifyContent="center">
            <Box height="auto" marginBottom={2} padding={0}>
              <UploadImage
                width={300}
                height={200}
                onCrop={onCrop}
                initialValue={initialValues.bizCardBackSideImg && initialValues.bizCardBackSideImg.url ? initialValues.bizCardBackSideImg.url : undefined}
              />
            </Box>
          </Box>
        </>
      )}
    </form>
  );
};

export default BusinessCardInfo;
