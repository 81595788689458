import { useState, useEffect } from 'react';
import { MutationFunctionOptions } from '@apollo/react-common';
import {
  createRestaurant as createRestaurantMutation,
  createTable as createTableMutation,
  deleteTable as deleteTableMutation,
  updateRestaurantProfile,
  updateRestaurantBusinessHours,
  updateRestaurantTable,
  updateRestaurantAnnouncement,
  updateRestaurantRewardSetting,
  updateRestaurantGallery,
  updateRestaurantPaymentSettings,
  updateRestaurantStatus,
  activateRestaurant as activateRestaurantMutation,
  deleteRestaurant as deleteRestaurantMutation,
  updateBizCardBackSide
} from 'graphql/mutations/restaurant.mutation';
import getRestaurantById, {
  getRestaurantBusinsessHours,
  getRestaurantProfile,
  getRestaurantTables,
  getRestaurantGallery,
  getRestaurantAnnouncement,
  getRestaurantRewards,
  getRestaurant,
  getRestaurantPaymentSettings,
  getRestaurantStatus,
  getRestaurantStoreCard,
  getMenuCard,
  getBizCard
} from 'graphql/query/getRestaurantById.query';
import { GET_RESTAURANT_BY_OWNER, GET_STRIPE_CONNECT_URL, GET_RESTAURANT_FEEDBACK, DOWNLOAD_TABLE_CARD } from 'graphql/query';

import { useQueryWithLoader, useMutationWithLoader, useLazyQueryWithLoader } from 'hooks/loader';
import { ExecutionResult } from 'apollo-boost';
import { useActiveRestaurant } from 'hooks/restaurant';
import { Profile, BusinessHours, Table, Announcement, RewardSetting, Gallery, PaymentSettings, Status } from 'graphql/fragments/restaurant.fragments';
import { filter } from 'lodash';
import Logger from 'util/logger';
import { BizCard } from 'graphql/fragments/cards.fregments';

export const useCreateRestaurantMutation = () => {
  const [createRestaurant, { data, loading, error }] = useMutationWithLoader(createRestaurantMutation, {
    update: (cache, { data: { createRestaurant: newData } }) => {
      const { getRestaurantsByOwner }: any = cache.readQuery({ query: GET_RESTAURANT_BY_OWNER });

      getRestaurantsByOwner.edges.push({ node: newData });

      cache.writeQuery({
        query: GET_RESTAURANT_BY_OWNER,
        data: { getRestaurantsByOwner }
      });
    }
  });

  return {
    createRestaurant,
    data,
    loading,
    error
  };
};

export const useDeleteRestaurantMutation = () => {
  const [deleteRestaurant, { data, loading, error }] = useMutationWithLoader(deleteRestaurantMutation, {
    refetchQueries: [{ query: GET_RESTAURANT_BY_OWNER }]
  });

  return {
    deleteRestaurant,
    data,
    loading,
    error
  };
};

export const useCreateTableMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [createTable, { data, loading, error }] = useMutationWithLoader(createTableMutation, {
    update: (cache, { data: { createTable: newData } }) => {
      if (restaurantId) {
        const id = `Restaurant:${restaurantId}`;
        const data: any = cache.readFragment({ fragment: getFragmentMapper('TABLE'), id });

        const { tables } = data;

        cache.writeFragment({
          id,
          fragment: getFragmentMapper('TABLE'),
          data: { id: restaurantId, tables: tables ? [...tables, newData] : [newData], __typename: 'Restaurant' }
        });
      }
    }
  });

  return {
    createTable,
    data,
    loading,
    error
  };
};

export const useDeleteTableMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [deleteTableCaller, { data, loading, error }] = useMutationWithLoader(deleteTableMutation);

  const deleteTable = (tableName: string, options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return deleteTableCaller({
      ...options,
      variables: {
        ...options?.variables,
        input: { ...options?.variables?.input }
      },
      update: (cache) => {
        if (restaurantId) {
          const id = `Restaurant:${restaurantId}`;
          const data: any = cache.readFragment({ fragment: getFragmentMapper('TABLE'), id });

          const { tables } = data;

          cache.writeFragment({
            id,
            fragment: getFragmentMapper('TABLE'),
            data: { id: restaurantId, tables: filter(tables, (table: any) => table.tableName !== tableName), __typename: 'Restaurant' }
          });
        }
      }
    });
  };

  return {
    deleteTable,
    data,
    loading,
    error
  };
};

type IRestaurantQueryOptions = 'ID' | 'PROFILE' | 'BUSINESS_HOURS' | 'TABLE' | 'GALLERY' | 'ANNOUNCEMENT' | 'REWARD_SETTING' | 'PAYMENT_SETTINGS' | 'STATUS' | 'ALL' | 'MENUCARD' | 'BIZCARD';

const getQueryMapper = (type: IRestaurantQueryOptions) => {
  const mapper = {
    ID: getRestaurantById,
    PROFILE: getRestaurantProfile,
    BUSINESS_HOURS: getRestaurantBusinsessHours,
    TABLE: getRestaurantTables,
    GALLERY: getRestaurantGallery,
    ANNOUNCEMENT: getRestaurantAnnouncement,
    REWARD_SETTING: getRestaurantRewards,
    ALL: getRestaurant,
    PAYMENT_SETTINGS: getRestaurantPaymentSettings,
    STATUS: getRestaurantStatus,
    MENUCARD: getMenuCard,
    BIZCARD: getBizCard
  };

  return mapper[type];
};

const getMutationMapper = (type: IRestaurantQueryOptions) => {
  const mapper: any = {
    PROFILE: updateRestaurantProfile,
    BUSINESS_HOURS: updateRestaurantBusinessHours,
    TABLE: updateRestaurantTable,
    ANNOUNCEMENT: updateRestaurantAnnouncement,
    REWARD_SETTING: updateRestaurantRewardSetting,
    GALLERY: updateRestaurantGallery,
    PAYMENT_SETTINGS: updateRestaurantPaymentSettings,
    STATUS: updateRestaurantStatus,
    BIZCARD: updateBizCardBackSide
  };

  return mapper[type];
};

const getFragmentMapper = (type: IRestaurantQueryOptions) => {
  const mapper: any = {
    PROFILE: Profile,
    BUSINESS_HOURS: BusinessHours,
    TABLE: Table,
    ANNOUNCEMENT: Announcement,
    REWARD_SETTING: RewardSetting,
    GALLERY: Gallery,
    PAYMENT_SETTINGS: PaymentSettings,
    STATUS: Status,
    MENUCARD: getMenuCard,
    BIZCARD: BizCard
  };

  return mapper[type];
};

export const useGetRestaurantById = (id: string, type: IRestaurantQueryOptions = 'ID') => {
  const query = getQueryMapper(type);

  const { data, refetch, loading } = useQueryWithLoader(query, {
    skip: !id ? true : false,
    variables: {
      input: {
        id
      }
    }
  });

  return { data, refetch, loading };
};

export const useUpdateRestaurantMutation = (type: IRestaurantQueryOptions = 'PROFILE', loader = true) => {
  const { restaurantId } = useActiveRestaurant();

  const mutation = getMutationMapper(type);

  const [updateRestaurantCaller, { data, loading, error }] = useMutationWithLoader(mutation, {
    update: (cache, { data: { updateRestaurant: newData } }) => {
      try {
        if (restaurantId) {
          const fragment = getFragmentMapper(type);

          const id = `Restaurant:${restaurantId}`;

          cache.writeFragment({
            id,
            fragment,
            data: newData
          });
        }
      } catch {
        console.log('[FRAGMENT_UPDATE] Failed to update fragment');
      }
    }
  });

  const updateRestaurant = (options?: MutationFunctionOptions<any, Record<string, any>> | undefined): Promise<ExecutionResult<any>> => {
    return updateRestaurantCaller({
      ...options,
      variables: {
        ...options?.variables,
        input: { ...options?.variables?.input, id: restaurantId }
      }
    });
  };

  return {
    updateRestaurant,
    data,
    loading,
    error
  };
};

export const useGetStripeConnectUrl = () => {
  const [getStripeUrl, { data, error }] = useLazyQueryWithLoader(GET_STRIPE_CONNECT_URL);

  return { data, getStripeUrl, error };
};

export const useActivateRestaurantMutation = () => {
  const { restaurantId } = useActiveRestaurant();
  const [activateRestaurant, { data, loading, error }] = useMutationWithLoader(activateRestaurantMutation, {
    update: (cache) => {
      if (restaurantId) {
        const id = `Restaurant:${restaurantId}`;

        cache.writeFragment({
          id,
          fragment: getFragmentMapper('STATUS'),
          data: { id: restaurantId, onlineStatus: 'ACTIVE', __typename: 'Restaurant' }
        });
      }
    }
  });

  return {
    activateRestaurant,
    data,
    loading,
    error
  };
};

export const useGetRestaurantStoreCard = (id: string) => {
  const data = useQueryWithLoader(getRestaurantStoreCard, {
    variables: {
      input: {
        bizId: id
      }
    },
    fetchPolicy: 'network-only'
  });

  return data;
};

export const useGetRestaurantFeedback = (restaurantId: string) => {
  const pageLength = 5;

  const [hasMore, setHasMore] = useState<boolean | null | undefined>(false);

  const [endCursor, setEndCursor] = useState<string | null | undefined>(null);

  const [fetching, setFetching] = useState(true);

  const { data, fetchMore } = useQueryWithLoader(GET_RESTAURANT_FEEDBACK, {
    variables: {
      input: {
        bizId: restaurantId,
        first: pageLength
      }
    }
  });

  useEffect(() => {
    if (data && data.getBizReviews) {
      const { getBizReviews } = data;

      if (getBizReviews.pageInfo) {
        const { hasNextPage, endCursor } = getBizReviews.pageInfo;

        setHasMore(hasNextPage);

        setEndCursor(endCursor);
      }
    }
    setFetching(false);
  }, [data]);

  const fetchMoreData = async () => {
    if (hasMore) {
      try {
        setFetching(true);
        await fetchMore({
          query: GET_RESTAURANT_FEEDBACK,
          variables: {
            input: {
              bizId: restaurantId,
              first: pageLength,
              after: endCursor
            }
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const { pageInfo } = fetchMoreResult.getBizReviews;
            const newCursor = pageInfo.endCursor;

            return {
              getBizReviews: {
                edges: [...previousResult.getBizReviews.edges, ...fetchMoreResult.getBizReviews.edges],
                pageInfo: {
                  endCursor: newCursor,
                  hasNextPage: pageInfo.hasNextPage,
                  __typename: pageInfo.__typename
                },

                __typename: previousResult.getBizReviews.__typename
              }
            };
          }
        });
      } catch (e) {
        setFetching(false);
        Logger.log((e as any).message);
      }
    }
  };

  return {
    data,
    fetchMoreData,
    fetching,
    hasMore
  };
};

export const useDownloadTableCards = () => {
  const [downloadTablesCard, { data: tableCardZipUrl }] = useLazyQueryWithLoader(DOWNLOAD_TABLE_CARD);

  return {
    downloadTablesCard,
    tableCardZipUrl
  };
};
