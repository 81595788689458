import {
  createDishMutation,
  updateDishMutation,
  deleteDishMutation,
  createDishCategoryMutation,
  updateDishCategoryOrderMutation,
  updateDishDisplayOrderMutation,
  deleteDishCategoryMutation,
  updateDishCategoryMutation
} from 'graphql/mutations/dish.mutation';
import { getDishesQuery, getDishQuery, getRewardItemsQuery } from 'graphql/query/dish.query';
import getDishCategoriesOfRestaurant from 'graphql/query/getDishCategoriesOfRestaurant';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { useStore } from 'store';
import { useEffect, useState } from 'react';
import { map, uniq, filter, findIndex, isEqual, remove, indexOf } from 'lodash';
import { useActiveRestaurant } from 'hooks/restaurant';

export const useUpdateDishDisplayOrderMutation = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updateDishDisplayOrder, { loading, data, error }] = useMutationWithLoader<any, any>(updateDishDisplayOrderMutation, {
    refetchQueries: [
      {
        query: getDishesQuery,
        variables: {
          input: {
            bizId: restaurantId
          }
        }
      }
    ]
  });

  return {
    updateDishDisplayOrder,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useUpdateDishCategoryOrderMutation = () => {
  const { restaurantId } = useActiveRestaurant();

  const [updateDishCategoryOrder, { loading, data, error }] = useMutationWithLoader<any, any>(updateDishCategoryOrderMutation, {
    refetchQueries: [
      {
        query: getDishCategoriesOfRestaurant,
        variables: {
          input: {
            bizId: restaurantId,
            noEmpty: false
          }
        }
      }
    ]
  });

  return {
    updateDishCategoryOrder,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useCreateDishMutation = () => {
  const { restaurantId } = useActiveRestaurant();

  const [createDish, { loading, data, error }] = useMutationWithLoader<any, any>(createDishMutation, {
    refetchQueries: [
      {
        query: getDishCategoriesOfRestaurant,
        variables: {
          input: {
            bizId: restaurantId,
            noEmpty: false
          }
        }
      }
    ],
    update: (cache, { data: { createDish: newData } }) => {
      try {
        const { getAllDishes }: any = cache.readQuery({
          query: getDishesQuery,
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        getAllDishes.push({ node: newData, __typename: 'DishConnectionEdge' });

        cache.writeQuery({
          query: getDishesQuery,
          data: { getAllDishes },
          variables: {
            input: {
              bizId: restaurantId
            }
          }
        });

        /**
         * Pushing new dish to reward list
         */

        if (newData.isReward) {
          const { getRewardItems }: any = cache.readQuery({
            query: getRewardItemsQuery,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });

          cache.writeQuery({
            query: getRewardItemsQuery,
            data: { getRewardItems: [newData, ...getRewardItems] },
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
        }
      } catch (e) {}
    }
  });

  return {
    createDish,
    loading,
    data,
    error,
    restaurantId
  };
};

interface IUpdateDishArgs {
  showLoader?: boolean;
}

export const useUpdateDishMutation = ({ showLoader = true }: IUpdateDishArgs = { showLoader: true }) => {
  const { restaurantId } = useActiveRestaurant();

  const [updateDish, { loading, data, error }] = useMutationWithLoader<any, any>(
    updateDishMutation,
    {
      refetchQueries: [
        {
          query: getDishCategoriesOfRestaurant,
          variables: {
            input: {
              bizId: restaurantId,
              noEmpty: false
            }
          }
        }
      ],
      update: (cache, { data: { updateDish: newData } }) => {
        try {
          const { getAllDishes: oldData }: any = cache.readQuery({
            query: getDishesQuery,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });

          let updatedDishes = [];

          if (newData.status === 'DELETED') {
            updatedDishes = filter(oldData, ({ id }: any) => id !== newData.id);
          } else {
            updatedDishes = map(oldData, (dish: any) => {
              if (dish.id === newData.id) {
                return { ...newData };
              } else return dish;
            });
          }

          cache.writeQuery({
            query: getDishesQuery,
            data: { getAllDishes: updatedDishes },
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
        } catch (e) {}

        try {
          /**
           * Reward items updating local apollo cache
           */
          const { getRewardItems: oldRewardItems }: any = cache.readQuery({
            query: getRewardItemsQuery,
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });

          let updatedRewardItems = [...oldRewardItems];

          if (newData.status === 'DELETED' || !newData.isReward) {
            updatedRewardItems = filter(oldRewardItems, ({ id }: any) => id !== newData.id);
          } else {
            //Adding dish to getRewardItems cache if not exist
            const index = findIndex(oldRewardItems, (item: any) => {
              return isEqual(item.id, newData.id);
            });

            if (index === -1) {
              updatedRewardItems = [newData, ...oldRewardItems];
            }
          }

          cache.writeQuery({
            query: getRewardItemsQuery,
            data: { getRewardItems: updatedRewardItems },
            variables: {
              input: {
                bizId: restaurantId
              }
            }
          });
        } catch (e) {}
      }
    },

    showLoader
  );

  return {
    updateDish,
    loading,
    data,
    error,
    restaurantId
  };
};

export const useDeleteDishMutation = ({ showLoader = true }: IUpdateDishArgs = { showLoader: true }) => {
  const { restaurantId } = useActiveRestaurant();

  const [deleteDish, { loading, data, error }] = useMutationWithLoader<any, any>(deleteDishMutation, {}, showLoader);

  return {
    deleteDish,
    loading,
    data,
    error,
    restaurantId
  };
};

interface IGetDishArgs {
  restaurantId: string | null;
}

export const useGetDishesQuery = ({ restaurantId }: IGetDishArgs) => {
  const { dispatch } = useStore();

  const [dishes, setDishes] = useState<any>(null);

  const { data, error, loading } = useQueryWithLoader(getDishesQuery, {
    variables: {
      input: {
        bizId: restaurantId
      }
    },
    skip: !restaurantId
  });

  useEffect(() => {
    if (data && data.getAllDishes) {
      const { getAllDishes } = data;

      const categories = uniq(
        map(getAllDishes, ({ category }) => {
          return category.title || '';
        })
      );

      dispatch({
        payload: categories,
        type: 'SET_CATEGORY_LIST'
      });

      setDishes(getAllDishes);
    }
  }, [data, dispatch]);

  return {
    loading,
    dishes,
    error
  };
};

export const useGetRewardItemsQuery = ({ restaurantId }: IGetDishArgs) => {
  const { data, error, loading } = useQueryWithLoader(getRewardItemsQuery, {
    variables: {
      input: {
        bizId: restaurantId
      }
    },
    skip: !restaurantId
    // fetchPolicy: 'network-only'
  });

  return {
    loading,
    data: data?.getRewardItems,
    error
  };
};

export const useGetDishQuery = (id: string | undefined | null) => {
  const { restaurantId } = useActiveRestaurant();

  const { loading, data, error } = useQueryWithLoader(getDishQuery, {
    variables: {
      input: {
        bizId: restaurantId,
        id
      }
    },
    skip: !restaurantId || !id
  });

  const dish = (data && data.getDish) || null;

  return {
    loading,
    dish,
    error
  };
};

export const useGetDishCategories = () => {
  const { restaurantId } = useActiveRestaurant();

  const { loading, data, error } = useQueryWithLoader(getDishCategoriesOfRestaurant, {
    variables: {
      input: {
        bizId: restaurantId,
        noEmpty: false
      }
    },
    skip: !restaurantId
  });

  return {
    loading,
    data,
    error
  };
};

export const useCreateDishCategory = () => {
  const [createDishCategory, { loading, data, error }] = useMutationWithLoader<any, any>(createDishCategoryMutation, {});

  return {
    createDishCategory,
    loading,
    data,
    error
  };
};

export const useDeleteDishCategory = (bizId: string, categoryId: string) => {
  const [deleteCategory, { data, loading, error }] = useMutationWithLoader(deleteDishCategoryMutation, {
    variables: {
      input: {
        bizId,
        id: categoryId
      }
    },
    update: (cache) => {
      const dishCategories: any = cache.readQuery({
        query: getDishCategoriesOfRestaurant,
        variables: {
          input: {
            bizId,
            noEmpty: false
          }
        }
      });

      const { getAllDishes }: any = cache.readQuery({
        query: getDishesQuery,
        variables: {
          input: {
            bizId
          }
        }
      });

      const updatedDishes = filter(getAllDishes, ({ category }: any) => (category ? category.id !== categoryId : true));

      const updatedCategories = filter(dishCategories.getDishCategoriesOfRestaurant ? dishCategories.getDishCategoriesOfRestaurant : [], ({ id }) => id !== categoryId);

      cache.writeQuery({
        query: getDishCategoriesOfRestaurant,
        data: { getDishCategoriesOfRestaurant: updatedCategories },
        variables: {
          input: {
            bizId,
            noEmpty: false
          }
        }
      });

      cache.writeQuery({
        query: getDishesQuery,
        data: { getAllDishes: updatedDishes },
        variables: {
          input: {
            bizId
          }
        }
      });
    }
  });

  return {
    deleteCategory,
    data,
    loading,
    error
  };
};

export const useUpdateDishCategory = () => {
  const [updateDishCategory, { data, loading, error }] = useMutationWithLoader(updateDishCategoryMutation);

  return {
    updateDishCategory,
    data,
    loading,
    error
  };
};
