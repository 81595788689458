import { IPizzaDish, IPizzaToppingGroup } from './types';
import { map, filter, groupBy } from 'lodash';
import { convertPrice } from 'util/number';

export const convertResponseToFormValues = (response: any) => {
  const output: IPizzaDish = {
    dishName: '',
    visible: true,
    popular: false,
    category: '',
    description: '',
    dishType: 'PIZZA',
    pizzaBasePrice: [],
    pizzaCrustIds: [],
    pizzaDefaultCrustId: '',
    pizzaCheese: [],
    pizzaSauce: [],
    pizzaToppings: []
  };

  if (response) {
    const { title, category, status, description, tags, type, pizzaBasePrice, pizzaCrust, pizzaCheese, pizzaSauce, pizzaToppingGroups } = response;

    output.dishName = title || '';

    output.category = category.title || '';

    output.description = description || '';

    if (tags && tags.length && tags.indexOf('POPULAR') > -1) {
      output.popular = true;
    }

    output.visible = status === 'ACTIVE';

    output.dishType = type;

    if (pizzaBasePrice) {
      const basePrices = map(pizzaBasePrice, (basePrice) => {
        const { id: sizeId } = basePrice.size || { id: '' };

        const { intValue, shift } = basePrice.price || { intValue: 0, shift: -2 };

        return {
          sizeId,
          price: convertPrice(intValue, shift)
        };
      });
      output.pizzaBasePrice = basePrices;
    }
    if (pizzaCrust) {
      output.pizzaCrustIds = map(pizzaCrust, ({ id, isDefault }) => {
        if (isDefault) {
          output.pizzaDefaultCrustId = id ? id : '';
        }
        return id ? id : '';
      });
    }

    if (pizzaSauce && pizzaSauce.items && pizzaSauce.items.length) {
      const sauces: any = groupBy(pizzaSauce.items, 'groupPartId');

      Object.keys(sauces).forEach((key: any) => {
        output.pizzaSauce.push({
          groupId: key,
          maxSelect: sauces[key].length,
          items: map(sauces[key] || [], ({ partId, preset }) => {
            return {
              partId: partId || '',
              preset
            };
          })
        });
      });
    }

    if (pizzaCheese && pizzaCheese.items && pizzaCheese.items.length) {
      const cheeses: any = groupBy(pizzaCheese.items, 'groupPartId');

      Object.keys(cheeses).forEach((key: any) => {
        output.pizzaCheese.push({
          groupId: key,
          maxSelect: cheeses[key].length,
          items: map(cheeses[key] || [], ({ partId, preset }) => {
            return {
              partId: partId || '',
              preset
            };
          })
        });
      });
    }

    if (pizzaToppingGroups && pizzaToppingGroups.length) {
      const pizzaToppings: IPizzaToppingGroup[] = [];

      pizzaToppingGroups.forEach((toppingGroup: any) => {
        const groupItems = toppingGroup.items || [];

        if (groupItems.length) {
          const groupId = groupItems[0].groupPartId;
          const maxSelect = toppingGroup.maxSelect || 0;

          const items = map(toppingGroup.items || [], ({ partId, preset }) => {
            return {
              partId: partId || '',
              preset
            };
          });

          pizzaToppings.push({
            groupId,
            maxSelect,
            items
          });
        }
      });

      output.pizzaToppings = pizzaToppings;
    }
  }

  return output;
};
