import { gql } from 'apollo-boost';

export default gql`
  query getBizOrderReport($input: GetBizOrderReportInput!) {
    getBizOrderReport(input: $input) {
      orders {
        order {
          id
          status
          deliveryProvider
          deliveryMode
          deliveryAddress {
            text
          }
          expectTime
          orderNumber
          createdAt
          timeLines {
            msg
            status
          }
          buyer {
            uid
            phoneNumber
            email
            displayName
          }
          orderEmail
          orderPhoneNumber
          paymentType
          type
          subTotal {
            intValue
            shift
          }
          total {
            intValue
            shift
          }
          couponDiscount {
            intValue
            shift
          }
          coupon {
            id
            code
          }
          tax {
            intValue
            shift
          }
          tip {
            intValue
            shift
          }
          lokobeeFee {
            intValue
            shift
          }
          deliveryFee {
            intValue
            shift
          }
          deliveryRequest {
            doordashClassicPayload {
              fee
            }
          }
          tableName
          partySize
          items {
            dishId
            count
            dishType
            isReward
            isDiscounted
            points
            dishTitle

            dishPrice {
              price {
                intValue
                shift
              }
              size
              discountAmount
            }

            pizzaBasePrice {
              id
              size {
                title
              }
              price {
                intValue
                shift
              }
              discountAmount
            }
            pizzaCrust {
              crustId
              title
              price {
                intValue
                shift
              }
            }

            pizzaSauces {
              toppingId
              toppingGroupId
              toppingGroupTitle
              side
              toppingTitle
              toppingDescription
              toppingDensity
              toppingPrice {
                intValue
                shift
              }
              preset
            }

            pizzaCheeses {
              toppingId
              toppingGroupId
              toppingGroupTitle
              side
              toppingTitle
              toppingDescription
              toppingDensity
              toppingPrice {
                intValue
                shift
              }
              preset
            }

            pizzaToppings {
              toppingId
              toppingGroupId
              toppingGroupTitle
              side
              toppingTitle
              toppingDescription
              toppingDensity
              toppingPrice {
                intValue
                shift
              }
              preset
            }
          }
        }
        serviceCharges {
          id
          bizCharge {
            intValue
            shift
          }
          tip {
            intValue
            shift
          }
          tax {
            intValue
            shift
          }
          subtotal {
            intValue
            shift
          }
          deliveryFee {
            intValue
            shift
          }
          doordashCharge {
            intValue
            shift
          }
          stripeFee {
            intValue
            shift
          }
          chargebackProtectionCharge {
            intValue
            shift
          }
          couponCost {
            intValue
            shift
          }
        }
      }
      pageCursor
    }
  }
`;
