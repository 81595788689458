import React, { useState, useEffect } from 'react';
import { chain, find, map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetDishCategories, useGetDishesQuery, useUpdateDishDisplayOrderMutation } from 'graphql/hooks/dish.hooks';
import Sortable from 'components/Sortable';
import { ISortableData } from 'generated/custom';
import { getUrlQuery } from '../util';

interface IProps {
  visibility: string;
}

const DishSorting: React.FC<IProps> = ({ visibility }) => {
  const location = useLocation();

  const activeCategory = getUrlQuery(location, 'category') || 'All';

  const { restaurantId } = useActiveRestaurant();

  const { dishes } = useGetDishesQuery({ restaurantId });

  const { data: dishCategoriesData } = useGetDishCategories();

  const { updateDishDisplayOrder } = useUpdateDishDisplayOrderMutation();

  const [masterData, setMasterData] = useState<ISortableData[] | null>(null);

  const [activedCategoryId, setActivatedCategoryId] = useState<string | null>(null);

  useEffect(() => {
    let dishArray = chain(dishes).filter(({ status }) => status === visibility);

    let data: { id: string; title: string }[] = [];

    if (activeCategory !== 'All') {
      dishArray = dishArray.filter(({ category }: any) => {
        const { id } = category;

        return id === activeCategory;
      });

      if (dishCategoriesData) {
        const { getDishCategoriesOfRestaurant } = dishCategoriesData;

        const activatedCategory = find(getDishCategoriesOfRestaurant, (category: any) => {
          const { id } = category;

          return id === activeCategory;
        })?.id;

        setActivatedCategoryId(activatedCategory);
      }
    }

    data = map(dishArray.value(), ({ id: dishId, title: dishTitleArray }, index: number) => {
      return { id: dishId, title: dishTitleArray || '' };
    });

    setMasterData(data);
  }, [activeCategory, dishes, dishCategoriesData, visibility]);

  const onSortSave = async (data: ISortableData[]) => {
    const dishIds = map(data, ({ id }) => {
      return id;
    });

    const response = await updateDishDisplayOrder({
      variables: {
        input: {
          bizId: restaurantId,
          catId: activedCategoryId,
          dishIds: dishIds
        }
      }
    });

    if (response && response.data) {
      return true;
    }

    return false;
  };

  if (masterData && masterData.length > 1 && activeCategory !== 'All') {
    return <Sortable dialogHeaderText="Dishes" buttonText="Rearrange" data={masterData} onSave={onSortSave} />;
  }

  return null;
};

export default DishSorting;
